import {
    BalButton,
    BalCard,
    BalCardContent,
    BalHeading,
    BalText,
} from "@baloise/ds-react";
import { useFeatureIsOn } from "@growthbook/growthbook-react";
import { useMediaQuery } from "@lg-cfa/hooks";
import { MediaQuery } from "@lg-cfa/interfaces-enums";
import classnames from "classnames";
import { graphql, navigate } from "gatsby";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";

import { Footer } from "@modules/shared/components";
import { FeatureFlags, PageNames } from "@modules/shared/enums";
import { AppState, appState } from "@modules/shared/state";
import { PageProps } from "@modules/shared/types";
import {
    BasicInformationBlock,
    CarInformationBlock,
    NextStepsCard,
    ResumeInsuranceBlock,
    SimulatorLayout,
} from "@modules/simulator";

const NextStepsPage: React.FC<PageProps> = ({ pageContext }) => {
    const { foundPage: page, ownPageObjects: allPages, language } = pageContext;
    const appData = useRecoilValue<AppState>(appState);
    const { t } = useTranslation();

    const contactPage = allPages.find((p) => p.name === PageNames.ContactMe);

    const quoteStartPage = allPages.find(
        (p) => p.name === PageNames.QuoteIntro,
    );

    const insurancePage = allPages.find((p) => p.name === PageNames.Insurance);

    const carInformationPage = allPages.find(
        (t) => t.name === PageNames.CarDescription,
    );

    const isQuoteFlowEnabled =
        useFeatureIsOn(FeatureFlags.FordEnableQuoteFlow as string) &&
        !appData.BeforeWeStart?.professionalUsage;

    const isMobile = useMediaQuery(MediaQuery.Mobile);

    return (
        <>
            <SimulatorLayout
                title={t("simulator.nextsteps.title")}
                page={page}
                language={language}
                allPages={allPages}
            >
                <main className="container is-compact mt-large flex flex-direction-column">
                    <div className="flex-1">
                        <div className="intro">
                            <BalHeading level="h1" subtitle>
                                {t("simulator.nextsteps.title")}
                            </BalHeading>
                            <BalText>{t("simulator.nextsteps.intro")}</BalText>
                        </div>
                        <div className="grid">
                            {isQuoteFlowEnabled && (
                                <div
                                    className={classnames(
                                        "col",
                                        {
                                            "is-full": isMobile,
                                        },
                                        {
                                            "is-half": !isMobile,
                                        },
                                    )}
                                >
                                    <NextStepsCard
                                        title="simulator.nextsteps.quote.title"
                                        subTitle="simulator.nextsteps.quote.subtitle"
                                        text="simulator.nextsteps.quote.text"
                                        testtag="ford-toquote"
                                    >
                                        <BalButton
                                            expanded
                                            rounded
                                            onClick={() =>
                                                navigate(
                                                    quoteStartPage?.paths[
                                                        language
                                                    ] ?? "",
                                                )
                                            }
                                        >
                                            {t(
                                                "simulator.nextsteps.quote.button",
                                            )}
                                        </BalButton>
                                    </NextStepsCard>
                                </div>
                            )}
                            <div
                                className={classnames(
                                    "col",
                                    {
                                        "is-full":
                                            !isQuoteFlowEnabled || isMobile,
                                    },
                                    {
                                        "is-half":
                                            isQuoteFlowEnabled && !isMobile,
                                    },
                                )}
                            >
                                <NextStepsCard
                                    title="simulator.nextsteps.contact.title"
                                    subTitle="simulator.nextsteps.contact.subtitle"
                                    text="simulator.nextsteps.contact.text"
                                    testtag="ford-tocontactme"
                                >
                                    <BalButton
                                        expanded
                                        rounded
                                        color="info"
                                        onClick={() =>
                                            navigate(
                                                contactPage?.paths[language] ??
                                                    "",
                                            )
                                        }
                                    >
                                        {t(
                                            "simulator.nextsteps.contact.button",
                                        )}
                                    </BalButton>
                                </NextStepsCard>
                            </div>
                        </div>
                        <BalHeading level="h2" subtitle className="mb-normal">
                            {t("simulator.nextsteps.selection.title")}
                        </BalHeading>
                        <BalCard className="mb-large">
                            <BalCardContent>
                                <ResumeInsuranceBlock
                                    editFunction={() =>
                                        navigate(
                                            insurancePage?.paths[language] ??
                                                "",
                                        )
                                    }
                                />
                                <CarInformationBlock
                                    editFunction={() =>
                                        navigate(
                                            carInformationPage?.paths[
                                                language
                                            ] ?? "",
                                        )
                                    }
                                    language={language}
                                />
                                <BasicInformationBlock />
                            </BalCardContent>
                        </BalCard>
                    </div>
                    <Footer />
                </main>
            </SimulatorLayout>
        </>
    );
};

export default NextStepsPage;

export const pageQuery = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
